import React, { useState, useEffect } from "react"
import Items from "../../components/projects/items"
import { graphql, useStaticQuery } from "gatsby"
import ProjectLayout from "../../components/projects/projectLayout"

const Projects = () => {
  const QUERY = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { relativeDirectory: { eq: "projects" } }
          sort: { fields: childMarkdownRemark___frontmatter___id }
        ) {
          nodes {
            childMarkdownRemark {
              frontmatter {
                id
                category
                name
                slug
                tools
                link
                desc
                thumbnail {
                  childImageSharp {
                    gatsbyImageData(
                      width: 200
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  var allData = QUERY.allFile.nodes
  const [data, setData] = useState(allData)
  const [category, setCategory] = useState("All Projects")

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [category])

  function updateData(c) {
    let filteredData
    let catArray = ["Web Application", "Website", "Games", "UI/UX Design"]

    if (c === "All") {
      setCategory("All Projects")
      setData(allData)
    } else if (c === "Others") {
      setCategory("Other Projects")
      filteredData = allData.filter(key => {
        for (let i = 0; i < catArray.length; i++) {
          if (key.childMarkdownRemark.frontmatter.category === catArray[i]) {
            return false
          }
        }
        return true
      })
      setData(filteredData)
    } else {
      setCategory(c)
      filteredData = allData.filter(
        key => key.childMarkdownRemark.frontmatter.category === c
      )
      setData(filteredData)
    }
  }

  return (
    <ProjectLayout updateData={updateData} state={true}>
      <Items projects={data} title={category} />
    </ProjectLayout>
  )
}

export default Projects
